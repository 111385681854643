import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import fond from 'assets/images/fond_sodeystviya_innovacii.svg';
import logo from 'assets/images/logo.svg';
import { ModalWindow } from 'components/universal_components';
import FooterButtons from './FooterButtons/FooterButtons';
import FooterContactLinks from './FooterContactLinks/FooterContactLinks';
import FooterContacts from './FooterContacts/FooterContacts';
import FooterNav from './FooterNav/FooterNav';
import styles from './FooterSection.module.scss';
import { ASK_QUESTION_MODAL, PHONE_CALL_MODAL } from './constants';

const FooterSection = () => {
  const [elementWindow, setElementWindow] = useState(); // текущий объект со значениями

  return (
    <footer className={styles.wrapper}>
      <div className="page_container">
        <div className={styles.upperLine}>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="FOS" />
          </Link>
          <div className={styles.nav}>
            <FooterNav />
          </div>
        </div>
        <div className={styles.centerLine}>
          <div className={styles.contacts}>
            <FooterContacts />
          </div>
          <div className={styles.buttons}>
            <FooterButtons />
          </div>
          <div className={styles.contactLinks}>
            <FooterContactLinks setElementWindow={setElementWindow} />
          </div>
          <div className={styles.partner}>
            <img src={fond} alt="Фонд содействия инновациям" />
          </div>
        </div>
      </div>
      {elementWindow && (
        <ModalWindow elementWindow={elementWindow} setElementWindow={setElementWindow}>
          {elementWindow === ASK_QUESTION_MODAL.name && ASK_QUESTION_MODAL.component}
          {elementWindow === PHONE_CALL_MODAL.name && PHONE_CALL_MODAL.component}
        </ModalWindow>
      )}
    </footer>
  );
};

export default FooterSection;
