/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
import React from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetHandle = ({ image, title, metaTitle, description, metaDescription, product = false }) => {
  if (description || metaDescription) {
    document.querySelector('meta[name="description"]')?.remove();
    document.querySelector('meta[property="og:description"]')?.remove();
  }

  if (metaTitle || title) {
    document.querySelector('meta[property="og:title"]')?.remove();
  }

  if (image) {
    document.querySelector('meta[property="og:image"]')?.remove();
  }
  // eslint-disable-next-line max-len
  // console.log(`image: ${image}, title: ${title}, metaTitle: ${metaTitle}, description: ${description}, metaDescription: ${metaDescription}`);
  return (
    <Helmet prioritizeSeoTags>
      {metaTitle
        ? <title>{`${metaTitle} | FOS Group`}</title>
        : <title>{`${title} | FOS Group`}</title>}

      {metaTitle
        ? <meta property="og:title" content={`${metaTitle} | FOS Group`} />
        : <meta property="og:title" content={`${title} | FOS Group`} />}

      {metaDescription
        ? <meta name="description" content={metaDescription} />
        : product === true
          ? null
          : <meta name="description" content={description} />}

      {metaDescription
        ? <meta property="og:description" content={metaDescription} />
        : product === true
          ? null
          : <meta property="og:description" content={description} />}

      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
};

export default HelmetHandle;
