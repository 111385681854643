import React from 'react';
import { useParams } from 'react-router-dom';
import { projectsAPI } from 'stateManager/services';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
// import { useTitle } from 'hooks';
import PageFooter from 'components/universal_components/PageFooter/PageFooter';
import ProjectImages from './ProjectImages/ProjectImages';
import styles from './ProjectPage.module.scss';

const ProjectPage = () => {
  const params = useParams();
  const { data: project, isFetching } = projectsAPI.useFetchProjectQuery(params.slug);
  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!project) {
    return <>not found</>;
  }

  const images = [{ id: 0, image: project.image }, ...project.additional_images];

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Проекты',
      link: '/projects',
    },
    {
      title: project.title,
      link: `/projects/${project.slug}`,
    },
  ];

  // useTitle(project.title);

  return (
    <div itemScope itemType="http://schema.org/Article" className={styles.wrapper}>
      <HelmetHandle
        image={project.image}
        title={project.title}
        metaTitle={project.meta_title}
        metaDescription={project.meta_description}
        product="true"
      />

      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>
        <h1 itemProp="name" className={styles.title}>{project.title}</h1>

        <div className={styles.images}>
          <ProjectImages images={images} />
        </div>

        <div className={styles.separator} />

        <div
          itemProp="description"
          className={styles.description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: project.description }}
        />

        <div className={styles.separator} />

        <PageFooter />
      </div>
    </div>
  );
};

export default ProjectPage;
