/* eslint-disable max-len */
import React from 'react';
import YoutubeBackground from 'assets/images/youtube_background.webp';
import styles from './VideoSection.module.scss';

const VideoSection = () => (
  <iframe
    className={styles.iframe}
    width="1835"
    height="650"
    // src="https://www.youtube.com/embed/_aYpEGsSoIo?si=mm6VEcsEknydPeJh"
    srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.3em black}</style><a href=https://www.youtube.com/embed/_aYpEGsSoIo?si=mm6VEcsEknydPeJh/?autoplay=1><img src=${YoutubeBackground} alt='Презентация производства светодиодных светильников FOS'><span>▶</span></a>`}
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  />
);

export default VideoSection;
